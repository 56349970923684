.btnClear {
    padding: 0px;
    color: #cacad5;
    cursor: pointer;
  
    position: absolute;
    top: 43px;
    right: 95px;
  }
  
  .btnClear:hover{
    color: #aaaab0;
  }