.navIco {
  font-size: 18px;
  margin-right: 5px;
}

.sizeLogo {
  width: 45px;
  height: auto;
  margin-right: 8px;
}

.sizeLogo5 {
  width: 90px;
  height: auto;
  margin-right: 8px;
}