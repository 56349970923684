.btnClear {
  padding: 0px;
  color: #aaa7a7;
  cursor: pointer;

  position: absolute;
  top: 12px;
  right: 30px;
}

.btnClear:hover{
  color: #b2b2c7;
}

.btnClearErr {
  padding: 0px;
  color: #aaa7a7;
  cursor: pointer;

  position: absolute;
  top: 12px;
  right: 12px;
}

.btnClearErr:hover{
  color: #b2b2c7;
}
