.rowHover:hover {
  background-color: #4D53E032 !important;                     
}

.rowHoverRed:hover {
  background-color: #a71f2e60 !important;                     
}


.hoverLink {
  color: #730A29;
  &:hover {
    color: #c82c30
  }
}

tr.tableHeader th {
color: #6b859e
}

tr.tableText td {
  color: #343a40
}

.thBB {
  border-bottom-width: 3px;
  border-bottom-style: solid;
}


.tableLimit {
  max-height: 300px;
  overflow-y:scroll;
  position: relative;
}

.tableLimit thead{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.trTT {
  // border-top: "2px solid #6C91C2";
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: #6C91C2;
  background-color: #6C91C230;
}