@import "../../styles/helpers";

.alertContainer {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 12px 16px;
  font-size: $font-size-sm;
  font-weight: $font-weight-base;
  color: $white;
}

.alertIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.messageContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;

  hr {
    width: 100%;
  }
}

