@keyframes flashAnimation {
    from {
        opacity: 0.75;
    }
    to {
        opacity: 0;
    }
}
.cameraVideo{
    position: absolute;
    object-fit: cover;
    left: 0;
}
.cameraContainer{
    width: 100%;
    overflow: hidden;
    min-height: 700px;
    max-width: 570px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    top: calc(50% - 350px)
}
.cameraOverlay{
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
    border: 1px solid #ffffff;
    border-radius: 10px;
}
.cameraOverlay.hidden{
    display: none;
}
.cameraFlash{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 0;
}
.preview img{
    position: absolute;
    border: 5px solid #ddd;
    z-index: 9;
    border-radius: 10px;
}
#preview-canvas{
    position: absolute;
    border: 5px solid transparent;
    z-index: 99;
}
#cameraCanvas{
    position: absolute;
    border: 5px solid transparent;
    z-index: 99;
    width: 100%;
    height: 100%;
}
.cameraFaceOverlay {
    position: absolute;
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    box-shadow: 0px 0px 20px 156px rgb(0 0 0 / 60%);
    border: 6px dashed #ddd;
    border-radius: 50%;
    margin: 20px 30px 20px;
}
.cameraFaceMessage{
    position: absolute;
    top: -50px;
    color: #ddd;
    font-weight: bold;
    font-size: large;
    text-align: center;
    width: 100%;
}