@import "../../styles/helpers";

.buttonInfo {
  border: none;
  background: transparent;
  display: flex;
  cursor: pointer;
}

.icon{
  font-size: 20px !important;
  color: #4d53e0 !important;
}
