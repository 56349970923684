.btnClear {
  padding: 0px;
  color: #cacad5;
  cursor: pointer;

  position: absolute;
  top: 43px;
  right: 95px;
  z-index: 3;
}

.btnClear:hover{
  color: #aaaab0;
}

.linkAdd{
  color: #314248;
  cursor: pointer;
}

.linkAdd:hover{
  color: #53707a;
}