.myBtn i{
 font-size: 13px;
}

.myBtn i.x2{
  font-size: 16px;
 }

i.icBtn{
  font-size: 16px;
}

.myBtn span {
  font-size: 15px;
 }

.myBtn.success{
  background-color: #4f9e30;
  border-color: #4f9e30;
}
.myBtn.success:active{
  background-color: #5fac40;
  border-color: #5fac40;
}

.myBtn.info{
  background-color: #4ab6f0;
  border-color: #4ab6f0;
}
.myBtn.info:active{
  background-color: #6eccff;
  border-color: #6eccff;
}

.myBtn.danger{
  background-color: #ff4141;
  border-color: #ff4141;
}
.myBtn.danger:active{
  background-color: #ff5151;
  border-color: #ff5151;
}

.myBtn.danger{
  background-color: #ff4141;
  border-color: #ff4141;
}
.myBtn.danger:active{
  background-color: #ff5151;
  border-color: #ff5151;
}

.myBtn.warning{
  background-color: #FFA100;
  border-color: #FFA100;
}
.myBtn.warning:active{
  background-color: #fdb02b;
  border-color: #fdb02b;
}

.colAction{
  width: 190px;
}

.colObject{
  width: 250px;
}

button.btnBack{
  background-color: #b43a3a;
  border-color: #b43a3a;
}

.mdText{
  font-size: 20;
}

.btp{
  font-weight: 600;
}