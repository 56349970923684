@import "../../styles/helpers";

.navbarBlock {
  display: flex;
  color: $icon-color;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 44px;
  width: 44px;
  border-radius: 50%;

  img {
    height: 100%
  }
}

.menuIcon {
  fill: $icon-color;
}

.notificationImage {
  margin: 8px auto 12px;
  width: 100%;
}

.mariaImage {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.count {
  position: absolute;
  width: 6px;
  height: 6px;
  top: 10px;
  right: 3px;
  background: $pink;
  border-radius: 50%;
}

.form-control::placeholder {
  color: red;
  opacity: 1;
}

.dropdownProfileItem {
  display: flex;
  &:hover {
    svg {
      path {
        fill: $default;
        stroke: $default;
      }
    }
  }
}

