@import "../../styles/helpers";

.root {
  //display: flex;
  //flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 0px 16px;
  background-color: $sidebar-color;
  overflow-y: auto;
  transition: height 1s;
  transform: translateX(0);
  z-index: 7;

  @media print {
    display: none;
  }

  :global(.sidebar-hide) & {
    display: none;
  }

  @media (max-width: breakpoint-max(sm)) {
    position: static !important;
    width: auto;
    margin: 0;
    border-radius: 8px;
    padding: 0;
    height: 0;
    transform: none;

    .logo,
    .sidebarAlerts,
    .sidebarLabels,
    .navTitle {
      display: none;
    }
  }
}

.sidebarOpen {
  width: $sidebar-width-open;
  transition: all 0.2s ease;
  @media (max-width: breakpoint-max(sm)) {
    height: 550px;
  }
}

.sidebarClose {
  width: $sidebar-width-closed;
  height: $sidebar-height-closed;
  padding: 0;
  transition: all 0.2s ease;
  overflow-y: unset;
  background: none;
  @media (max-width: breakpoint-max(sm)) {
    height: 0;
    width: 0;
    display: none;
  }
}

.sidebarArrowOpen {
  display: flex;
  @media (max-width: breakpoint-max(sm)) {
    display: none;
  }
}

.sidebarSelectProfile {
  background-color: $red-client;
  border-radius: .25rem;
  font-weight: $font-weight-semi-bold;
  color: $white;
  padding: 10px 5px;
  white-space: nowrap;
  gap: 5px;
}

.sidebarSelectProfile span{
  color: white !important;
}

.sidebarProfileContainer span:nth-child(2) {
  font-size: 12px;
}

.sidebarProfileContainer {
  display: flex;
  cursor: pointer;
  font-size: 1rem;
  flex-direction: column;
  border-bottom: 2px solid $red-client;
}

.sidebarProfileContainer span {
  color: $default;
}

.logo {
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  margin: 16px 0 24px;
  width: 100%;
  background-color: $sidebar-color;
  z-index: 9;

  .title {
    margin-left: 12px;
    font-size: 11pt;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

.nav {
  padding-bottom: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  li > a > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
  }
}

.navTitle {
  margin: 32px 0 8px;
  font-size:$font-size-sm;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
  text-transform: uppercase;
}

.unlockBtn {
  a {
    color: whitesmoke;
  }
}

