.trDangerBg {
  background-color: #FF4B2335;                      
}

.trPrimaryBg {
  background-color: #4d53e035 !important;                      
}
.trDefaultBg {
  background-color: #16365f35;                      
}

.rowLayout:hover {
  background-color: #6495ed30;
}

.rowSelected{
  font-weight: 700;
  background-color: #6495ed60 !important;

}