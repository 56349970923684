html {
  font-family: $font-family-base;
  font-weight: 100;
  font-size: $font-size-initial;
}

body {
  overflow-x: hidden;
  color: $text-color;
  background: $body-bg;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

html,
body,
#app {
  height: 100%;
}

p {
  margin-bottom: 0;
}

a,
.dropdown-item,
.btn {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  font-family: $font-family-base;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

th {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
}

td {
  color: $default;
}

.table td {
  vertical-align: middle;
}

ul ul,
ol ul {
  list-style: none;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

.timeline-header{
  min-height: "80px" !important;
  height: "80px";
}

.timeLine-main-header-viewPort {
  flex: 0 0 80px;
  position: relative;
  height: 80px;
  width: 100%;
  background-color: rgb(112, 112, 112);
  overflow: hidden;
}

.timeLine-side-title {
  min-height: 80px;
  height: 80px;
  max-height: 80px;
  background-color: #fbf9f9 !important;
  color: black !important;
}

.timeLine-side-title div {
  display: none;
}

.timeLine-side-title:after {
  content: 'Actividades';
  font-size: medium;
  font-weight: bold;
}

.timeLine-side-task-row{
  font-weight: bold;
  color: darkgray;
}
.timeLine-side-task-row div{
  white-space: nowrap;
  overflow-x: scroll;
  text-align: end;
}