//FullScreen
.loaderBack {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.3s;
}
.white{
  background: rgba(255, 255, 255, 0.7);
}

/* CENTER LOADING SPINNER */
.loaderBack .ico, .loaderFormBack .ico {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

/* SHOW & HIDE */
.loaderBack {
  visibility: hidden;
  opacity: 0;
}

//Full form
.loaderFormBack {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  transition: opacity 0.3s;
}

.loaderFormBack {
  visibility: hidden;
  opacity: 0;
}


.show {
  visibility: visible;
  opacity: 1;
}

.loaderAlertBack{
  position: absolute;
  
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  transition: opacity 0.3s;
  
}